<template>
	<HomeTamplate>
		<form @submit.prevent="signUp()">
			<div class="columns flex-wrap  has-text-left">
				<div class="column is-12 pd-b-less" :class="maxWidth > 1170 ? 'has-text-right':'has-text-centered'">
					<a :href="webAvocado"><img src="@/assets/logoAvocado.png" /></a>
				</div>
				<div class="column is-9 mg-x-auto">
					<h1 class="is-size-3 has-text-centered text-color-primary">สมัครสมาชิก</h1>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="ชื่อ"
						:type="{'is-danger':errors.has('name')}"
	                    :message="errors.has('name')?'Please fill out name':''">
			            <b-input v-validate="'required'" rounded v-model="form.name" name="name"></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="นามสกุล"
						:type="{'is-danger':errors.has('surname')}"
	                    :message="errors.has('surname')?'Please fill out surname':''">
			            <b-input v-validate="'required'" rounded v-model="form.surname" name="surname"></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="เบอร์โทรศัพท์" :type="{'is-danger':errors.has('telephoneNumber')}"
	                    :message="errors.has('telephoneNumber')?'Please fill out Telephone number':''">
			            <b-input  v-validate="'integer|min:9|required'" name="telephoneNumber"  rounded v-model="form.telephoneNumber"></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="บริษัท" :type="{'is-danger':errors.has('organizeName')}"
	                    :message="errors.has('organizeName')?'Please fill out company name':''">
			            <b-input rounded v-model="form.organizeName" name="organizeName" v-validate="'required'" ></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="ตำแหน่ง" :type="{'is-danger':errors.has('position')}"
	                    :message="errors.has('position')?'Please fill out position':''">
			            <b-input rounded v-model="form.position" name="position"  v-validate="'required'" ></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="อีเมล"
						:type="{'is-danger':errors.has('email')}"
	                    :message="errors.has('email')?'Please fill out email':''">
			            <b-input v-validate="'required'" rounded v-model="form.email" name="email" :disabled="disableEmail"></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto pd-b-less">
					<b-field label="รหัสผ่าน"
						:type="{'is-danger':errors.has('password')}"
	                    :message="errors.has('password')?'Please fill out password':''">
			            <b-input name="password" v-validate="'required'" rounded type="password" v-model="form.password" password-reveal></b-input>
			        </b-field>
				</div>
				<div class="column is-6 mg-x-auto">
					<b-field label="ยืนยันรหัสผ่าน"
						:type="{'is-danger':errors.has('confirmPassword')}"
	                    :message="errors.has('confirmPassword')?'Please fill out Confirm password':''">
			            <b-input name="confirmPassword" v-validate="'required'" rounded type="password" v-model="form.confirmPassword" password-reveal></b-input>
			        </b-field>
				</div>
		        <div class="column is-6 mg-x-auto mg-x-auto mg-t-5">
		        	<div class="buttons">
		        		<button type="submit" class="pd-y-5 bt-color-avocado is-rounded w-100 button is-info" rounded expanded>สมัครสมาชิก</button>
		        	</div>
		        </div>
				<div class="column is-12">
					<div class="has-text-centered">
			        	<p>คุณมีบัญชีอยู่แล้ว? <router-link to="/sign-in">เข้าสู่ระบบ</router-link></p>
			        </div>
				</div>
			</div>
		</form>
	</HomeTamplate>
</template>
<script>
import {HTTP} from '@/service/axios'
import HomeTamplate from '@/template/HomeTemplate'
	export default{
		mounted(){
			if(typeof this.object.uid != 'undefined'){
				this.form.userId = this.object.uid
				this.form.email = this.object.email
				this.disableEmail = true
			}
		},
		props: {
			object: {
				type: Object,
				default: null
			},
		},
		components:{
			HomeTamplate
		},
		data(){
			return {
				disableEmail:false,
				form:{
					userId:'',
					name:'',
					surname:'',
					telephoneNumber:'',
					position:'',
					organizeName:'',
					email:'',
					password:'',
					confirmPassword:'',
					fromType: "avocadox"
				},
				maxWidth: window.innerWidth,
				windowHeight:window.innerHeight,
				webAvocado: process.env.VUE_APP_WEB_AVOCADO,

			}
		},
		methods:{
			signUp(){
				this.$validator.validate().then(async(valid) => {
					if (valid) {
						HTTP.post('/sign/up',this.form).then(res=>{
							if(res.data.success){
								this.$buefy.toast.open({
									duration: 5000,
									message: 'สมัครสมาชิกสำเร็จ',
									type: 'is-success'
								})
								this.$router.push('/sign-in')
							}else if(res.data.data =="email is not work"){
								this.$buefy.toast.open({
									duration: 3000,
									message: 'อีเมลนี้ถูกใช้งานแล้ว',
									type: 'is-danger'
								})
							}else{
								this.$buefy.toast.open({
									duration: 3000,
									message: res.data.data+' กรุณาติดต่อผู้ดูแล',
									type: 'is-danger'
								})
							}
						}).catch(e=>{
							console.log('/sign/up error',e)
						})
					}
				})
			},
		}
	}
</script>